import * as React from "react"
import { useState, useEffect } from "react"
import Layout from "../components/Layout/Layout"
import Ingredients from "../components/Ingredients/Ingredients"
import NutrientInfo from "../components/NutrientInfo/NutrientInfo"
import { RECIPE_DETAIL_HERO } from "../components/ImageHeroBanner/ImageHeroBanner-tw-styles"
import ImageHeroBanner from "../components/ImageHeroBanner/ImageHeroBanner"
import Video from "../components/UI/Video/Video"
import { unitChangeIngredient } from "../helpers/utils"
import { ListingSteps } from "../components/ListingSteps/ListingSteps"
import { SimilarRecipes } from "../components/SimilarRecipes/SimilarRecipes"
import { RECIPE_VIDEO } from "../components/UI/Video/RecipeDetails-tw-styles"
import {
  INGREDIENT_PHOTO_ID,
  BANNER_PHOTO_ID,
  NUTRITION_PHOTO_ID,
  labels,
} from "../constants/label.constants"
import IconButton from "../components/IconButtons/IconButton"
import ImageWithText from "../components/ImageWithText/ImageWithText"
import { LEFT_QUOTE_CONTAINER } from "../components/ImageWithText/ImageWithText-tw-styles"
import PropTypes from "prop-types"
import { GTMScript, gTagScriptConsent } from "../components/Scripts/Gtm.Head"
import { ReceipeListStyles } from "../components/ListingSteps/ListingSteps-tw-styles"
import useScreenSize from "../hooks/useScreenSize"
import Link from "../components/UI/Link/Link"
import { labels as recipesListLabels } from "../constants/recipeListing.constants"

const RecipeDetails = props => {
  const { pageContext } = props
  const {
    recipeData,
    cmsRecipe = [],
    globalComponents,
    similarRecipes = [],
    dataLayer,
  } = pageContext
  const {
    IngredientList = [],
    PhotoURLAdditional,
    RecipeName,
    RecipeID,
    Videos,
    MoodText,
    Servings,
    TimeSpans,
    Classifications,
    Tips,
    ...other
  } = recipeData || {}

  const { prepTime = 0, totalTime = 0 } = TimeSpans || {}

  // GTM custom dataLayer Object based on environment
  if (typeof window !== "undefined") {
    const dataLayerObj = {
      ...dataLayer,
      page: {
        url: window.location.href,
        title: seoData?.title || seoData?.metaTitle,
      },
      content: {
        type: "Recipe Detail Page",
        category: "",
      },
    }
    window.gtmDataLayer = dataLayerObj
  }

  const screen = useScreenSize()
  const getImageUrl = photoTypeID => {
    const filterImage = PhotoURLAdditional?.find(
      item => item.PhotoTypeID === photoTypeID
    )
    return filterImage?.PhotoLink || ""
  }

  const toHoursAndMinutes = totalMinutes => {
    if (totalMinutes >= 60) {
      const hours = Math.floor(totalMinutes / 60)
      const minutes = totalMinutes % 60
      return `${hours}h ${minutes}min `
    } else return `${totalMinutes} min`
  }

  const getSimilarRecipeResults = () => {
    let calssificationIDs = Classifications?.map(item => {
      return item?.Id
    })
    const objKeys =
      similarRecipes?.length &&
      similarRecipes?.map(obj => {
        return parseInt(Object.keys(obj)[0])
      })

    const matchedID =
      objKeys?.length &&
      objKeys
        .filter(Boolean)
        .filter(obj => calssificationIDs?.indexOf(obj) !== -1)
    const recipeData =
      matchedID?.length >= 1 &&
      similarRecipes?.filter(obj => {
        return parseInt(Object.keys(obj)[0]) == matchedID[0]
      })
    const similarRecipeData =
      recipeData?.length == 1 && Object.values(recipeData[0])
    return similarRecipeData?.length == 1 ? similarRecipeData[0] : null
  }
  let videoImage = {
    url: getImageUrl(BANNER_PHOTO_ID),
    alt: RecipeName,
    width: 128,
    height: 72,
  }
  let bannerData = {
    heading: RecipeName,
    image: {
      url: getImageUrl(screen?.isMobile ? NUTRITION_PHOTO_ID : BANNER_PHOTO_ID),
      alt: RecipeName,
      width: 1283,
      height: 722,
    },
    backLink: {
      label: labels.back,
      title: labels.back,
      url: recipesListLabels?.slug,
      target: "_self",
      gaEventClass: "event_button_click",
      gaEventLabel: labels.back,
    },
  }
  if (Videos && Videos[0] && Videos[0].VideoURL) {
    bannerData["link"] = {
      scrollToElementId: "recipe-video-box",
      label: labels.watch,
    }
  }
  const [ingredients, setingredients] = useState({})

  // to convert any fraction number to decimal in ingredient list
  const transformedList = list => {
    return list.map(item => {
      return { ...item, QuantityText: eval(item.QuantityText) }
    })
  }

  useEffect(() => {
    setingredients({
      ingredientList: transformedList(IngredientList),
      image: getImageUrl(INGREDIENT_PHOTO_ID)
        ? {
            url: getImageUrl(INGREDIENT_PHOTO_ID),
          }
        : null,
      servings: recipeData?.Servings || 1,
    })
  }, [])

  const checkUnitType = item =>
    item.Type === labels.kJ || item.Type === labels.kcal

  const nutritionalData = recipeData?.Nutritions?.map(item => {
    let type = checkUnitType(item) ? labels.energy : item.Type
    type = type.charAt(0).toUpperCase() + type.slice(1)

    const value = `${Number(item.Value).toFixed(1)} ${
      checkUnitType(item) ? item.Type : labels.ingredients.g
    }`
    return {
      unit:
        type === labels.sat_fat
          ? labels.saturated_fat
          : type === labels.sodium
          ? labels.salt
          : type === labels.dit_fibre
          ? labels.dietary_fibre
          : type,
      value: value,
    }
  })
  const nutrientInfo = {
    text: labels.enjoyCreating,
    recipeTitle: RecipeName,
    nutritionTitle: labels.nutritionTitle,
    nutrientData: {
      rowHeading: [labels.typicalValues, labels.perServing],
      rowData: nutritionalData,
    },
    image: {
      url: getImageUrl(NUTRITION_PHOTO_ID),
      width: "457",
    },
  }
  const seoData = {
    metaTitle: `${labels.metaTitle} | ${RecipeName} `,
    metaDescription: labels.metaDesRecipe,
    ogTitle: `${labels.metaTitle} | ${RecipeName} `,
    title: `${labels.metaTitle} | ${RecipeName} `,
    ogUrl: `recipes/${RecipeName}-${RecipeID}`,
  }
  const updateQty = (qty, prevCount, servingsCount) => {
    const newList = ingredients?.ingredientList
      ?.map(item => {
        return unitChangeIngredient(qty, item, prevCount, servingsCount)
      })
      .filter(Boolean)
    setingredients({ ...ingredients, ingredientList: newList })
  }

  const recipeStepsData = {
    recipeSteps: recipeData?.PreparationSteps?.PreparationTypes,
    heading: labels.making,
    subheading: `${recipeData?.RecipeName}`,
  }
  const backLink = cmsRecipe?.length && cmsRecipe[2]?.link
  return (
    <>
      <Layout seoData={seoData} globalComponents={globalComponents}>
        <div id={labels.recepieDetail}>
          {bannerData && (
            <ImageHeroBanner variant={"RECIPE_DETAIL_HERO"} {...bannerData} />
          )}
          {/* Serving and social media section  */}
          <div className="flex mx-auto mt-[28px] mb-[50px] justify-between max-w-full w-[876px] md:flex-row flex-col-reverse p-8 md:px-30 lg:px-0 printwidth">
            <div className="cookTime ">
              <div className="md:text-3xl md:leading-[40px] font-ProxBold text-[25px] md:text-left text-center">
                {MoodText || labels.recipeDeveloped}
              </div>
              <div className="grid grid-cols-3 justify-center gap-20 md:w-[379px] w-[300px] py-20 text-sm md:mx-0 mx-auto">
                <div className="portion prepTime font-ProxBold child:font-ProxRegular child:text-15">
                  {Servings} <div>{labels.servings}</div>
                </div>
                <div className="time-prep prepTime font-ProxBold child:font-ProxRegular child:text-15">
                  {toHoursAndMinutes(prepTime)}
                  <div>{labels.prepTime}</div>
                </div>
                <div className="time-total prepTime font-ProxBold child:font-ProxRegular child:text-15">
                  {toHoursAndMinutes(totalTime)}
                  <div>{labels.totalTime}</div>
                </div>
              </div>
            </div>
            <div className="social-media-and-quick-use-buttons">
              <IconButton
                keyValue="recepieDetail"
                variant={"socialIconVar"}
                links={cmsRecipe?.length && cmsRecipe[0]}
              ></IconButton>
            </div>
          </div>
          {/* Ingredient section  */}
          {ingredients && (
            <Ingredients
              data={ingredients}
              updateQty={updateQty}
              variant={"receipe"}
            />
          )}
          {/* video section */}
          <div
            id="recipe-video-box"
            className=" relative height-[50px] top-[-60px]"
          ></div>
          {Videos && Videos[0] && (
            <div>
              <Video
                variant={"RECIPE_VIDEO"}
                style={RECIPE_VIDEO}
                isModal={false}
                youtubeVideoId={Videos[0].VideoURL.split("?v=")[1]}
                isImageToggle={true}
                posterImage={videoImage}
              />
            </div>
          )}
          {/* Recipe Steps */}
          {recipeStepsData && (
            <div className="recipe-steps">
              <ListingSteps
                {...recipeStepsData}
                style={ReceipeListStyles}
                proTips={Tips}
              />
            </div>
          )}

          {/* receipe inspiration */}
          {cmsRecipe?.length && (
            <div className="recipe-img">
              <ImageWithText {...cmsRecipe[1]} style={LEFT_QUOTE_CONTAINER} />
            </div>
          )}

          {/* Nutritional section  */}
          <NutrientInfo {...nutrientInfo} />
        </div>
        <div className={"recipe_section mb-70 print-noshow"}>
          {/* Recipes section */}
          <SimilarRecipes
            similarRecipe={getSimilarRecipeResults()}
            classicsData={cmsRecipe?.length && cmsRecipe[2]}
          />

          <Link {...backLink} className={"buttonEffect2 receipeCheckBtn"} />
        </div>
      </Layout>
    </>
  )
}

export const Head = () => {
  const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}

  return (
    <>
      <script
        id="first-unique-id"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
        }}
      />
      <script
        async
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: gTagScriptConsent }}
      />
      <script
        async
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: GTMScript }}
      />
    </>
  )
}

RecipeDetails.propTypes = {
  pageContext: PropTypes.shape({
    recipeData: PropTypes.object,
    cmsRecipe: PropTypes.array,
    globalComponents: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    similarRecipes: PropTypes.array,
    dataLayer: PropTypes.object,
  }),
}

export default RecipeDetails
